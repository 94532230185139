
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

$(function () {
    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content') }
    });
});


$(document).ready(function(){

	var timerFBWidget = null;

	changeFBPagePlugin = function () {
		if($('.facebookWidget').length <= 0)
			return;
	   //getting parent box width
	   var container_width = (Number($('.facebookWidget').width()) - Number($('.facebookWidget').css('padding-left').replace("px", ""))).toFixed(0);
	   //getting parent box height
//	   var container_height = (Number($('.fb-column').height()) - (Number($('.fb-column-header').height()) + Number($('.fb-column-   header').css('margin-bottom').replace("px", "")) + Number(($('.fb-column').css('padding-top').replace("px", "")*2)))).toFixed(0);
	   if (!isNaN(container_width)) {
	   		$(".fb-page").stop().fadeOut();
	   		if(container_width > 500){
	   			$('.facebookWidget').addClass('text-center');
	   		}else{
	   			$('.facebookWidget').removeClass('text-center');
	   		}
			$('.facebookWidget').html('<div class="see-facebook-news"><a href="#" onclick="$(this).closest(\'.facebookWidget\').toggleClass(\'open-mobile\')"><i class="fa fa-chevron-down"></i> Voir les actus</a></div><div class="fb-page" data-href="https://www.facebook.com/aquawantz/" data-width="' + container_width + '" data-tabs="timeline, messages, events" data-height="540" data-small-header="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/aquawantz/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/aquawantz/">Aquarive</a></blockquote></div>');
		    
		    //$(".fb-page").attr("data-width", container_width);//.attr("data-height", container_height);
		    console.log(container_width);
	    }
	    if (typeof FB !== 'undefined' ) {
	    	FB.XFBML.parse();
	   		$(".fb-page").stop().fadeIn();
	    }else{
	   		console.log('FB XFBML NOT DEFINED')
	    }
	}


	$(window).on('resize', function() {
		clearTimeout(timerFBWidget);
		timerFBWidget = setTimeout(function(){changeFBPagePlugin()}, 500);
	});
	 
	$(window).on('load', function() {
		clearTimeout(timerFBWidget);
		timerFBWidget = setTimeout(function(){changeFBPagePlugin()}, 0);
	});


    var popupNewsletter = new Foundation.Reveal($('#newsletterSignInWidget'));

    $('[data-confirm]').click(function(e){
    	if($(this).is('[data-authorize]')){
    		window.location.href = $(this).attr('href');
    	}else{
	    	e.preventDefault();

	    	$text = $(this).attr('data-confirm');
	    	$link = $(this);

			jconfirm({
				useBootstrap: false,
				boxWidth: '40%',
			    title: '<i class="fi-alert"></i> Cette action néccessite une confirmation de votre part !',
			    content: $text,
			    buttons: {
			        cancel: {
			        	btnClass: 'button pink',
			        	text : 'Annuler',
			        },
			        confirm: {
			        	btnClass: 'button red',
			        	text : "Valider",
			        	action: function () {

					    	$link.attr('data-authorize', '')
					    	$link.click();
					    	return true;
				        }
			        },

			    }
			});    	
		}

    });

    $('body').on('click', '[data-pop-in="edit-address"]', function(e){
    	$id = $(this).data('address-id');

		jconfirm({
			useBootstrap: false,
			boxWidth: '30%',
		    title: 'Gestion des adresses',
		    draggable: true,
		    content: 'Voulez vous supprimer l\'adresse ?',
		    buttons: {
		        cancel:	{
		        	btnClass: 'button pink',
		        	text : "Annuler",
		        	action: function () {
//			            jconfirm({content:'Canceled !'});
			        }
		        },
		        confirm: {
		        	btnClass: 'button red',
		        	text : "Valider",
		        	action: function () {
						$.ajax({ 
							url : routes['delete-my-address'],
							type : 'GET',
							data : {"id" : $id},
							contentType : 'json'
						})   
						  .done(function(resp){
						  	if(resp.state)
						  		$type = "green";
						  	else
						  		$type = "red";

				            jconfirm({
				            	content:resp['feedback'],
								type: $type,
								typeAnimated: true,				            	
				            });
						});

			        }
		        },
		    }
		});


    });
	
	
	$('body').on('submit', '[form-ajax="true"]', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		$inboxModal = $(this).closest('.inner');
		
		$datas = new FormData($(this)[0]);

		$.ajax({
			type: "POST",
			url: $(this).attr('action'),
			data: $datas,
			processData: false,
			contentType: false,
			dataType: "html",
			success: function(data, textStatus, jqXHR) {
				console.log(data);
				$inboxModal.html(data);
				setTimeout(function(){
					$inboxModal.find('.ico').addClass('drawn');
				}, 300);				
				
				//process data
			},
			error: function(data, textStatus, jqXHR) {
				//process error msg
			},		
		})
		

	})


	$(document).on('closed.zf.reveal', '[data-reveal][data-refresh-on-close]', function () {
		location.reload();
	});

})


